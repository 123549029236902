import React from "react";
import cx from "classnames";

import * as styles from "../styles/roundedButton.module.css";

const RoundedButton = ({ text, color }) => {
  return <button className={cx(styles.button, styles[color])}>{text}</button>;
};

export default RoundedButton;
