import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";

import RoundedButton from "../components/RoundedButton";

import * as styles from "../styles/home.module.css";

export default function IndexPage() {
  return (
    <div className={styles.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Leaderboard Golf App</title>
      </Helmet>
      <h1 className={styles.title}>Leaderboard</h1>
      <Link to="/privacy">
        <RoundedButton text="Privacy Policy" color="white" />
      </Link>
      <Link to="/support">
        <RoundedButton text="Support" color="blue" />
      </Link>
    </div>
  );
}
